export const SORT_FIELD_KEY = {
	LAST_MODIFIED_DATE: 'latestModifiedDate',
	CREATED_DATE: 'createdDate',
} as const;

export const SORT_ORDER = {
	ASC: 'ASC',
	DESC: 'DESC',
} as const;

const SORT_FIELD_KEY_LAST_MODIFIED_DATE_DESC =
	`${SORT_FIELD_KEY.LAST_MODIFIED_DATE}${SORT_ORDER.DESC}` as const;
const SORT_FIELD_KEY_LAST_MODIFIED_DATE_ASC =
	`${SORT_FIELD_KEY.LAST_MODIFIED_DATE}${SORT_ORDER.ASC}` as const;
const SORT_FIELD_KEY_CREATED_DATE_DESC =
	`${SORT_FIELD_KEY.CREATED_DATE}${SORT_ORDER.DESC}` as const;
const SORT_FIELD_KEY_CREATED_DATE_ASC = `${SORT_FIELD_KEY.CREATED_DATE}${SORT_ORDER.ASC}` as const;

export type ResultsSortOptionKey =
	| typeof SORT_FIELD_KEY_LAST_MODIFIED_DATE_DESC
	| typeof SORT_FIELD_KEY_LAST_MODIFIED_DATE_ASC
	| typeof SORT_FIELD_KEY_CREATED_DATE_DESC
	| typeof SORT_FIELD_KEY_CREATED_DATE_ASC;

export type ResultsSortOptions = {
	[TField in ResultsSortOptionKey]: {
		field: (typeof SORT_FIELD_KEY)[keyof typeof SORT_FIELD_KEY];
		order: (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
		labelKey: TField;
		selectedLabelKey: `${TField}Selected`;
		drawerOrder: number;
	};
};

export const RESULTS_SORT_FIELDS = {
	[SORT_FIELD_KEY_LAST_MODIFIED_DATE_DESC]: {
		field: SORT_FIELD_KEY.LAST_MODIFIED_DATE,
		order: SORT_ORDER.DESC,
		labelKey: SORT_FIELD_KEY_LAST_MODIFIED_DATE_DESC,
		selectedLabelKey: `${SORT_FIELD_KEY_LAST_MODIFIED_DATE_DESC}Selected`,
		drawerOrder: 0,
	},
	[SORT_FIELD_KEY_LAST_MODIFIED_DATE_ASC]: {
		field: SORT_FIELD_KEY.LAST_MODIFIED_DATE,
		order: SORT_ORDER.ASC,
		labelKey: SORT_FIELD_KEY_LAST_MODIFIED_DATE_ASC,
		selectedLabelKey: `${SORT_FIELD_KEY_LAST_MODIFIED_DATE_ASC}Selected`,
		drawerOrder: 1,
	},
	[SORT_FIELD_KEY_CREATED_DATE_DESC]: {
		field: SORT_FIELD_KEY.CREATED_DATE,
		order: SORT_ORDER.DESC,
		labelKey: SORT_FIELD_KEY_CREATED_DATE_DESC,
		selectedLabelKey: `${SORT_FIELD_KEY_CREATED_DATE_DESC}Selected`,
		drawerOrder: 2,
	},
	[SORT_FIELD_KEY_CREATED_DATE_ASC]: {
		field: SORT_FIELD_KEY.CREATED_DATE,
		order: SORT_ORDER.ASC,
		labelKey: SORT_FIELD_KEY_CREATED_DATE_ASC,
		selectedLabelKey: `${SORT_FIELD_KEY_CREATED_DATE_ASC}Selected`,
		drawerOrder: 3,
	},
} satisfies ResultsSortOptions;

export const ResultsSortOptions = Object.values(RESULTS_SORT_FIELDS).sort(
	(a, b) => a.drawerOrder - b.drawerOrder,
);

export type ResultsSortField = (typeof SORT_FIELD_KEY)[keyof typeof SORT_FIELD_KEY];
export type ResultsSortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];

export const getOptionKey = (field: ResultsSortField, order: ResultsSortOrder) =>
	`${field}${order}` as ResultsSortOptionKey;

export type ResultsSort = {
	field: ResultsSortField;
	order: ResultsSortOrder;
};
